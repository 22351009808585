<template>
    <div id="licznik">00:00</div>
</template>

<script>
    import CallHub from '@/callHub.js'
export default {
  name: 'Home',
data() {
    return {
        interval: null,
    }
    },
    mounted() {
        try {
            this.startHubConnection(),
            this.hubInfo()
        } catch (e) {
            console.log(e)
        }
    },
    methods: {
        startHubConnection() {
            CallHub.client.start().then(function () {
                console.log("polaczono")
            }).catch(function (err) {
                return console.error(err.toString());
            });
        },
        hubInfo() {
            var thisVue = this;
            CallHub.client.on("SendTime", function (time) {
                clearInterval(thisVue.interval);
                thisVue.countTimer(time);
            });

            CallHub.client.on("Stop", function () {
                thisVue.countTimer(0);
                document.getElementById("licznik").style.color = "white";
                document.getElementById("licznik").innerHTML = "00:00";
            });
        },
        countTimer(value) {
            var t = value;
            if (t == 0) {
                clearInterval(this.interval);
            } else {
                this.interval = setInterval(function () {
                    if (t > 0) {
                        //var hours = Math.floor(t % (3600 * 24) / 3600);
                        var minutes = Math.floor(t % 3600 / 60);
                        var seconds = Math.floor(t % 60);
                        document.getElementById("licznik").style.color = "white";
                        var minToDisp = (minutes < 10) ? "0" + minutes : minutes;
                        var secToDisp = (seconds < 10) ? "0" + seconds : seconds;
                        document.getElementById("licznik").innerHTML = minToDisp + ":" + secToDisp;
                        t--;
                    }
                    if (t == 0) {
                        new Audio('https://tablet.esesja.pl/ding3.mp3').play();
                    }
                    if (t <= 0) {
                        document.getElementById("licznik").style.color = "red";
                        var bez = Math.abs(t);
                        //var hours2 = Math.floor(bez % (3600 * 24) / 3600);
                        var minutes2 = Math.floor(bez % 3600 / 60);
                        var seconds2 = Math.floor(bez % 60);
                        var minToDisp2 = (minutes2 < 10) ? "0" + minutes2 : minutes2;
                        var secToDisp2 = (seconds2 < 10) ? "0" + seconds2 : seconds2;
                        document.getElementById("licznik").innerHTML = "-" + minToDisp2 + ":" + secToDisp2;
                        t--;
                    }
                }, 1000);
            }
        }
        },
}
</script>
<style>
    body {
        background: #000
    }
    div#licznik {
        color: #fff;
        font-family: Arial;
        font-weight: bold;
        margin: auto;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 19em;
        padding-top: 190px;
    }

        div#licznik.czerwony {
            color: red
        }

    ul#czasy {
        color: #fff;
        list-style: none;
        padding: 0px;
        margin: 65px;
    }

    li {
        display: inline-block;
        width: 200px;
        height: 129px;
        border: 1px solid #fff;
        margin-right: 10px;
        margin-bottom: 10px;
        font-family: Arial;
        font-size: 1.5em;
        padding-top: 45px;
        cursor: pointer;
    }

    div#poglad {
        color: #fff;
        position: fixed;
        bottom: 20px;
        right: 30px;
        font-size: 10em;
        font-family: Arial;
        font-weight: bold;
        left: 20px;
        text-align: center;
    }
</style>
