<template>
    <div>
        <ul id="czasy">
            <li @click="sendTime(0)"><p>30 sekund</p></li>
            <li @click="sendTime(1)">1 minuta</li>
            <li @click="sendTime(2)">2 minuty</li>
            <li @click="sendTime(3)">3 minuty</li>
            <li @click="sendTime(4)">4 minuty</li>
            <li @click="sendTime(5)">5 minut</li>
            <li @click="sendTime(10)">10 minut</li>
            <li @click="sendTime(15)">15 minut</li>
            <li @click="sendTime(20)">20 minut</li>
            <li @click="sendTime(30)">30 minut</li>
            <li @click="sendTime(45)">45 minut</li>
            <li @click="sendTime(60)">60 minut</li>
            <li @click="stop()">STOP</li>
        </ul>

        <div id="poglad">00:00</div>
    </div>
</template>


<script>
    import CallHub from '@/callHub.js'
    export default {
        name: "Counter",
        data() {
            return {
                interval: null
            }
        },
        mounted() {
            try {
                this.startHubConnection()
            } catch (e) {
                console.log(e)
            }
        },
        methods: {
            startHubConnection() {
                CallHub.client.start().then(function () {
                    console.log("polaczono")
                }).catch(function (err) {
                    return console.error(err.toString());
                });
            },
            async sendTime(item) {
                try {
                    var date = 0;
                    if (item == 0)
                        date = 30;
                    else
                        date = item * 60;

                    CallHub.client.invoke("SendTime", date).catch(function (err) {
                        return console.error(err.toString());
                    });
                    clearInterval(this.interval);
                    this.countTimer(date);
                } catch (e) {
                    console.log(e)
                }
            },
            async stop() {
                try {
                    CallHub.client.invoke("Stop").catch(function (err) {
                        return console.error(err.toString());
                    });
                    this.countTimer(0);
                    document.getElementById("poglad").style.color = "white";
                    document.getElementById("poglad").innerHTML = "00:00";
                } catch (e) {
                    console.log(e)
                }
            },
            countTimer(value) {
                var t = value;
                if (t == 0) {
                    clearInterval(this.interval);
                } else {
                    this.interval = setInterval(function () {
                        if (t > 0) {
                            //var hours = Math.floor(t % (3600 * 24) / 3600);
                            var minutes = Math.floor(t % 3600 / 60);
                            var seconds = Math.floor(t % 60);
                            var minToDisp = (minutes < 10) ? "0" + minutes : minutes;
                            var secToDisp = (seconds < 10) ? "0" + seconds : seconds;
                            document.getElementById("poglad").style.color = "white";
                            document.getElementById("poglad").innerHTML = minToDisp + ":" + secToDisp;
                            t--;
                        }
                        if (t == 0) {
                            new Audio('https://tablet.esesja.pl/ding3.mp3').play();
                        }
                        if (t <= 0) {
                            document.getElementById("poglad").style.color = "red";
                            var bez = Math.abs(t);
                            //var hours2 = Math.floor(bez % (3600 * 24) / 3600);
                            var minutes2 = Math.floor(bez % 3600 / 60);
                            var seconds2 = Math.floor(bez % 60);
                            var minToDisp2 = (minutes2 < 10) ? "0" + minutes2 : minutes2;
                            var secToDisp2 = (seconds2 < 10) ? "0" + seconds2 : seconds2;
                            document.getElementById("poglad").innerHTML = "-" + minToDisp2 + ":" + secToDisp2;
                            t--;
                        }
                    }, 1000);
                }
            }
        },
    }
</script>
<style>
    body {
        background: #000
    }

    div#licznik {
        color: #fff;
        font-family: Arial;
        font-weight: bold;
        margin: auto;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 19em;
        padding-top: 190px;
    }

        div#licznik.czerwony {
            color: red
        }

    ul#czasy {
        color: #fff;
        list-style: none;
        padding: 0px;
        margin: 65px;
    }

    li {
        display: inline-block;
        width: 200px;
        height: 129px;
        border: 1px solid #fff;
        margin-right: 10px;
        margin-bottom: 10px;
        font-family: Arial;
        font-size: 1.5em;
        padding-top: 45px;
        cursor: pointer;
    }

    li,p {
        text-align: center;
    }

    div#poglad {
        color: #fff;
        position: fixed;
        bottom: 20px;
        right: 30px;
        font-size: 10em;
        font-family: Arial;
        font-weight: bold;
        left: 20px;
        text-align: center;
    }

</style>