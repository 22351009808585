import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import Counter from "@/components/Counter.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/admin_ce96133b-e546-4fc7-b17a-f568ab131179",
        name: "Counter",
        component: Counter,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;